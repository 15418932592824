<template>
  <div class="login">
    <div class="centered-container">
      <md-content v-if="!blocked" class="md-elevation-3">

        <div class="title">
          <img src="@/assets/images/logo-altice-empresas-h-b.png">
          <!-- <div class="md-title">Public Information</div> -->
          <!-- <div class="md-body-1">Build beautiful apps with Material Design and Vue.js</div> -->
        </div>

        <div class="form">
          <md-field :class="{'md-invalid': validation.hasError('login.username')}">
            <label>Domain\User</label>
            <md-input v-model="login.username" autofocus></md-input>
            <span class="md-error">{{ validation.firstError('login.username') }}</span>
          </md-field>

          <md-field md-has-password :class="{'md-invalid': validation.hasError('login.password')}">
            <label>Password</label>
            <md-input v-model="login.password" type="password" @keyup.enter="auth"></md-input>
            <span class="md-error">{{ validation.firstError('login.password') }}</span>
          </md-field>
        </div>

        <div class="actions md-layout md-alignment-center-space-between">
          <!-- <a href="/password-reset">Esqueci-me da password</a> -->
          <div></div>
          <md-button class="md-raised md-primary" @click="auth">Entrar</md-button>
        </div>

        <div class="loading-overlay" v-if="loading">
          <md-progress-spinner md-mode="indeterminate" :md-stroke="2"></md-progress-spinner>
        </div>

      </md-content>
      <card-dialog v-if="blocked" text="Bloqueado" subTitle="Utilizador bloquado, é preciso resetar sua password!" icon="block"></card-dialog>
    </div>
  </div>
</template>

<script>
// import Projects from '@/components/Projects.vue'
import Auth from '@/api/services/Auth.js'
import SimpleVueValidation from 'simple-vue-validator'
import Swal from 'sweetalert2'
import * as Constants from '@/store/constants'
import CardDialog from '@/components/CardDialog.vue'
const Validator = SimpleVueValidation.Validator

export default {
  name: 'AdLogin',
  data () {
    return {
      loading: false,
      login: {
        username: '',
        password: ''
      },
      blocked: false
    }
  },
  components: {
    CardDialog
  },
  validators: {
    'login.username': function (value) {
      return Validator.value(value).required()
    },
    'login.password': {
      cache: true,
      debounce: 200,
      validator: (value) => {
        return Validator.value(value).required().minLength(9, Constants.VALIDATE_MINLENGHT)
      }
    }
  },
  methods: {
    auth () {
      this.loading = true
      if (!this.login.username || !this.login.password) {
        Swal.fire(
          'Error!',
          'Todos os campos são obrigatórios',
          'error'
        )
        this.loading = false
      } else {
        this.$validate().then((success) => {
          if (success) {
            Auth.loginAd(this.login).then((response) => {
              this.loading = false
              // (response)
              this.$store.commit('setLoginInternal')
              this.$store.commit('setLogin', response.data)
              if (this.$route.query.redirect) {
                this.$router.push('/altice' + this.$route.query.redirect)
              } else {
                this.$router.push('/altice/projects')
              }
            }).catch((error) => {
              this.loading = false
              // (error)
              if (error.response.status === 401) {
                this.$store.commit('tryLogin', this.login.username)
                if (this.$store.state.try > 3) {
                  this.blocked = true
                }
                Swal.fire(
                  'Error!',
                  'Não autorizado',
                  'error'
                )
              } else {
                Swal.fire(
                  'Error!',
                  'Não autorizado',
                  'error'
                )
              }
            })
          } else {
            this.loading = false
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
.centered-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .title {
    text-align: center;
    margin-bottom: 30px;
    img {
      margin-bottom: 16px;
      max-width: 160px !important;
    }
  }
  .actions {
    .md-button {
      margin: 0;
    }
  }
  .form {
    margin-bottom: 60px;
  }
  .md-content {
    z-index: 1;
    padding: 40px;
    width: 100%;
    max-width: 400px;
    position: relative;
  }
  .loading-overlay {
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
